<template>
  <v-snackbar
    v-model="showSnackBar"
    :color="color"
    style="font-family: 'Catamaran', serif !important;"
    :timeout="timeout"
    >
        {{ title }}
    <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="showSnackBar = false"
        >
            Close
        </v-btn>
    </template>
    </v-snackbar>
</template>

<script>

export default {
    props: ['title', 'status', 'color'],
    data () {
        return {
            snackbar: false,
            text: `Hello, I'm a snackbar`,
            timeout: 6000
        }
    },
    computed: {
        showSnackBar: {
            get(){
                return this.status
            },
            set(val){
                this.$emit('update:status', val)
                return false
            }
        }
    }
}
</script>
