<template>
    <v-dialog
        v-model="dialog"
        width="1200"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        persistent
        class="dialog-style"
    >
    <v-card>
        <v-card-title class="secondary py-1" style="height:40px;">
            <span class="dialog-title">Patient</span>
            <v-spacer />
            <v-icon small color="primary" @click="$emit('false')">mdi-window-close</v-icon>
        </v-card-title>
        <v-card-text color="primary" class="mt-5">
            <!-- <span>Edit profile</span> -->
        </v-card-text>
        <v-card-text>
            <v-form v-model="valid">
                <v-card flat style="overflow: hidden">
                    <v-row class="pa-6" dense>
                        <v-col cols="6">
                            <v-row class="pl-4 pb-4">
                                <span style="font-size: 18px;">Personal details</span>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-text-field
                                        v-model="userDetails.firstName"
                                        label="Name *"
                                        prepend-inner-icon="mdi-account mr-1"
                                        dense
                                        background-color="#E8E8E8"
                                        rounded
                                        outlined
                                        style="border-radius: 12px !important; height: 40px"
                                        :rules="[(rule) => !!rule || '']"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-text-field
                                        v-model="userDetails.lastName"
                                        label="Surname *"
                                        prepend-inner-icon="mdi-account mr-1"
                                        dense
                                        background-color="#E8E8E8"
                                        rounded
                                        outlined
                                        style="border-radius: 12px !important; height: 40px"
                                        :rules="[(rule) => !!rule || '']"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-text-field
                                        v-model="userDetails.email"
                                        label="Email"
                                        prepend-inner-icon="mdi-email mr-1"
                                        dense
                                        background-color="#E8E8E8"
                                        rounded
                                        outlined
                                        style="border-radius: 12px !important; height: 40px"
                                        :rules="[(rule) => !!rule || '']"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-text-field
                                        ref="phoneNumber"
                                        v-model="userDetails.phone"
                                        validate-on-blur
                                        label="Phone number *"
                                        prepend-inner-icon="mdi-cellphone mr-4"
                                        dense
                                        background-color="#E8E8E8"
                                        rounded
                                        outlined
                                        style="border-radius: 12px !important; height: 40px"
                                        :rules="[(rule) => !!rule || '']"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row dense cols="12">
                                <v-col cols="12" lg="6" md="6" sm="12">
                                    <v-radio-group class="pl-10 pt-0" v-model="userDetails.gender" row>
                                        <v-radio label="Male" value="M"></v-radio>
                                        <v-radio label="Female"  value="F"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12" lg="6" md="6" sm="12">
                                    <BirthdatePicker :inputValue.sync="userDetails.birthdateAsString" />
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-row class="pa-4 pt-8">
                                    <span style="font-size: 18px;">Address details</span>
                                </v-row>
                                <v-col cols="12" lg="12" md="12" sm="12">
                                    <v-text-field
                                        ref="address"
                                        @blur="locationDetails()"
                                        v-model="userDetails.address"
                                        validate-on-blur
                                        label="Address, State, Postal Code, City, Country *"
                                        prepend-inner-icon="mdi-map-marker mr-4"
                                        dense
                                        background-color="#E8E8E8"
                                        rounded
                                        outlined
                                        placeholder="Address, State, Postal Code, City, Country"
                                        style="border-radius: 12px !important; height: 40px"
                                        :rules="[(rule) => !!rule || '']"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-row class="pa-4 pt-8" style="cursor: pointer" @click="changePasswordSection = !changePasswordSection">
                                    <span style="font-size: 18px;">Change Password</span>
                                </v-row>
                            </v-row>
                            <v-row v-if="changePasswordSection" dense cols="12">
                                <v-col cols="12" lg="12" md="12" sm="12">
                                    <v-form v-model="valid" class="">
                                        <v-row class="pt-5 px-2">
                                            <v-text-field
                                                class="px-0 mx-0"
                                                type="password"
                                                :rules="currentPasswordRule"
                                                v-model="currentPassword"
                                                label="Current Password"
                                                prepend-inner-icon="mdi-lock-check mr-1"
                                                outlined
                                                dense
                                                filled
                                                style="border-radius: 12px;"
                                            ></v-text-field>
                                        </v-row>
                                        <v-row dense class="pt-0 px-0">
                                            <v-text-field
                                                :rules="newPasswordRule"
                                                v-model="newPassword"
                                                type="password"
                                                label="New Password"
                                                prepend-inner-icon="mdi-lock-plus mr-1"
                                                outlined
                                                dense
                                                filled
                                                style="border-radius: 12px;"
                                            >
                                            </v-text-field>
                                        </v-row>
                                        <v-row dense class="pt-n5 px-n5">
                                            <v-text-field
                                                :rules="confirmNewPasswordRule"
                                                v-model="confirmNewPassword"
                                                type="password"
                                                label="Confirm New Password"
                                                prepend-inner-icon="mdi-lock-reset mr-1"
                                                outlined
                                                dense
                                                filled
                                                style="border-radius: 12px;"
                                            >
                                            </v-text-field>
                                        </v-row>
                                        <v-card-actions>
                                            <v-spacer/>
                                            <v-btn class="table-create-button mx-n2" :disabled="!valid" @click="changePassword">
                                                <!-- <v-icon class="icon">mdi-plus</v-icon> -->
                                                <span>Change</span>
                                            </v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="pl-4" cols="6">
                            <v-row class="pl-4 pb-4">
                                <span style="font-size: 18px;">Units</span>
                            </v-row>
                            <v-row dense>
                                <v-card-text class="mb-0">
                                    <v-row dense>
                                    <v-col class="pt-4">
                                        <h6 class="primary--text">Weight & Temperature</h6>
                                    </v-col>
                                    <v-col class="pl-10">
                                        <v-chip-group active-class="primary white--text" column mandatory v-model="scaleAndTempUnit">
                                        <v-chip
                                            class="text-center"
                                            value="imperial"
                                            @click="setImperialOrMetric('imperial')"
                                            style="width: 80px; justify-content: center"
                                            >Imperial</v-chip
                                        >
                                        <v-chip
                                            value="metric"
                                            @click="setImperialOrMetric('metric')"
                                            style="width: 80px; justify-content: center"
                                            >Metric</v-chip
                                        >
                                        </v-chip-group>
                                    </v-col>
                                    </v-row>
                                    <v-row dense>
                                    <v-col class="pt-4">
                                        <h6 class="primary--text">Glucose & Cholesterol</h6>
                                    </v-col>
                                    <v-col class="pl-10">
                                        <v-chip-group
                                        active-class="primary white--text"
                                        column
                                        mandatory
                                        v-model="glucoseAndCholesterolUnit"
                                        >
                                        <v-chip
                                            value="mg/dL"
                                            @click="setGlucoseAndCholesterolUnit('mg/dL')"
                                            style="width: 80px; justify-content: center"
                                            >mg/dL</v-chip
                                        >
                                        <v-chip
                                            value="mmol/L"
                                            @click="setGlucoseAndCholesterolUnit('mmol/L')"
                                            style="width: 80px; justify-content: center"
                                            >mmol/L</v-chip
                                        >
                                        </v-chip-group>
                                    </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <div class="my-6">
                            <v-btn class="table-create-button" @click="$emit('false')">
                                <v-icon class="icon-cancel">mdi-window-close</v-icon>
                                <span>{{ $t('cancel') }}</span>
                            </v-btn>
                            <v-btn class="table-create-button mx-4" :disabled="!valid" @click="update">
                                <v-icon class="icon">mdi-plus</v-icon>
                                <span>{{ $t("save") }}</span>
                            </v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-card-text>
        <Alert
            :title="title"
            :status="snackStatus"
            :color="snackColor"
          ></Alert>
    </v-card>
    </v-dialog>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import BirthdatePicker from "@/views/Client/components/BirthdatePicker";
import image from "@/assets/account-outline.png"
import { AsYouType } from 'libphonenumber-js';
import Alert from "@/views/Client/components/Alert.vue";

export default {
    props: ['dialog', 'specializations'],
    components: {
        BirthdatePicker,
        Alert
    },
    data () {
        return {
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            valid: false,
            changePasswordSection: false,
            profile: image,
            firstName: null,
            lastName: null,
            birthdate: null,
            phoneNumber: null,
            address: null,
            email: null,
            gender: null,
            scaleAndTempUnit: "",
            glucoseAndCholesterolUnit: "",
            address_info: {
                address: "",
                postal_code: "",
                city: "",
                state: "",
                country: "",
                lat: 42.001071,
                lng: 20.957554,
            },
            formattedAddress: null,
            timezone: null,
            title: null,
            snackStatus: null,
            snackColor: null
        }
    },
    computed: {
        ...mapState({
            userDetails: (state) => state.authentication.userData,
            verifiersFromRepo: (state) => state.verifiers.verifiers,
        }),
        ...mapGetters({
            units: "metrics/getUnits",
        }),
        currentPasswordRule() {
            if (this.currentPassword === "") {
                return ["Field should not be empty!"];
            } else if (this.currentPassword.length < 7) {
                return ["Password should have more than 6 characters!"];
            } else {
                return [];
            }
        },
        newPasswordRule() {
            if (this.newPassword === "") {
                return ["Field should not be empty!"];
            } else if (this.newPassword.length < 7) {
                return ["Password should have more than 6 characters!"];
            } else {
                return [];
            }
        },
        confirmNewPasswordRule() {
            if (this.confirmNewPassword === "") {
                return ["Field should not be empty!"];
            } else if (this.confirmNewPassword !== this.newPassword) {
                return ["Passwords do not match", true];
            } else {
                return [];
            }
        },
    },
    watch:{
        'userDetails.phone': function (val){
            if (val !== undefined) {
                this.userDetails.phone = new AsYouType().input(val);
            }
        },
    },
    mounted () {
        this.scaleAndTempUnit = this.units.scaleAndTempUnit;
        this.glucoseAndCholesterolUnit = this.units.glucoseAndCholesterolUnit;
        this.addressData()
    },
    methods: {
        async addressData () {
            this.address_info = {
                address: this.userDetails.address,
                postal_code: this.userDetails.zip,
                city: this.userDetails.city,
                state: this.userDetails.state,
                country: this.userDetails.country,
                lat: this.userDetails.addressLatitude,
                lng: this.userDetails.addressLongitude,
            }
            this.timezone = this.userDetails.userTimezone
        },
        async locationDetails () {
            if (this.userDetails.address) {
                const geo_location = await this.$store.dispatch('googleAPI/getGeoLocation', this.userDetails.address)
                const address_components = geo_location.address_components
                this.address_info.lat = geo_location.geometry.location.lat
                this.address_info.lng = geo_location.geometry.location.lng
                this.formattedAddress = geo_location.formatted_address
                this.address_info.address = this.formattedAddress
                address_components.forEach(ac => {
                    if (ac.types.includes('administrative_area_level_1')) {
                        this.address_info.state = ac.long_name
                    }
                    if (ac.types.includes('locality')) {
                        this.address_info.city = ac.long_name
                    }
                    if (ac.types.includes('country')) {
                        this.address_info.country = ac.long_name
                    }
                    if (ac.types.includes('postal_code')) {
                        this.address_info.postal_code = ac.long_name
                    }
                })
                if (this.address_info.lat !== null) {
                    const tz = await this.timeZone(this.address_info.lat, this.address_info.lng)
                    if (tz) {
                        this.timezone = tz.zoneName
                    }
                }
            }
        },
        async timeZone(lat, lng) {
            let object = {
                lat: lat,
                lng: lng
            }
            const time_zone = await this.$store.dispatch('googleAPI/getTimeZone', object)
            return time_zone
        },
        setImperialOrMetric(unit) {
            this.scaleAndTempUnit = unit;
            this.changePreferences();
        },
        setGlucoseAndCholesterolUnit(unit) {
            this.glucoseAndCholesterolUnit = unit;
            this.changePreferences();
        },
        changePreferences() {
            const body = {
                scaleAndTempUnit: this.scaleAndTempUnit,
                glucoseAndCholesterolUnit: this.glucoseAndCholesterolUnit,
            };
            this.$store.dispatch("metrics/setMetric", body);
        },
        async update() {
            this.snackStatus = false;
            const body = {
                id: this.userDetails.id,
                firstName: this.userDetails.firstName,
                lastName: this.userDetails.lastName,
                phone: this.userDetails.phone.split(' ').join(''),
                email: this.userDetails.email,
                address: this.address_info.address,
                zip: this.address_info.postal_code,
                city: this.address_info.city,
                state: this.address_info.state,
                country: this.address_info.country,
                addressLatitude: this.address_info.lat,
                addressLongitude: this.address_info.lng,
                userTimezone: this.timezone,
                username: this.userDetails.username,
                status: this.userDetails.status,
                gender: this.userDetails.gender,
                roleId: this.userDetails.roleId,
                birthdate: this.birthdateAsString,
            };
            await this.$store.dispatch("users/updateUser", body)
                .then(() => {
                    this.$emit('false')
                    this.snackStatus = true;
                    this.snackColor = "teal darken-2";
                    this.title = this.$t("profileUpdatedSuccessfully");
                })
                .catch(() => {
                    this.snackStatus = true;
                    this.snackColor = "deep-orange darken-4";
                    this.title = this.$t("profileCouldNotUpdate");
                });
        },
        async changePassword() {
            const body = {
                currentPassword: this.currentPassword,
                newPassword: this.confirmNewPassword,
            };
            await this.$store
                .dispatch("users/changePassword", body)
                .then((res) => {
                    if (res.resFlag) {
                        this.changePasswordSection = false
                        this.currentPassword = ""
                        this.newPassword = ""
                        this.confirmNewPassword = ""
                        this.snackStatus = true;
                        this.snackColor = "teal darken-2";
                        this.title = this.$t("passwordChangedSuccessfully");
                    }
                })
                .catch(() => {
                    this.changePasswordSection = false
                    this.snackStatus = true;
                    this.snackColor = "deep-orange darken-4";
                    this.title = this.$t("passwordCouldNotBeChanged");
                    this.currentPassword = ""
                    this.newPassword = ""
                    this.confirmNewPassword = ""
                });
        },
    }
}
</script>