<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="textValue"
          label="Birthdate *"
          prepend-inner-icon="mdi-calendar mr-4"
          readonly
          outlined
          style="border-radius: 12px !important; height: 40px"
          dense
          background-color="#E8E8E8"
          rounded
          class="mb-3"
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="textValue"
        no-title
        :active-picker.sync="activePicker"
        :max="
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        "
        min="1900-01-01"
        @change="save"
      ></v-date-picker>
    </v-menu>
  </div>
</template>


<script>
export default {
  props: {
    inputValue: String,
  },
  data: () => ({
    activePicker: null,
    date: null,
    menu: false,
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  computed: {
    textValue: {
      get() {
        return this.inputValue;
      },
      set(newValue) {
        this.$emit("update:inputValue", newValue);
      },
    },
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
  }
};
</script>