let dashboard_black = require('./icons_v2/dashboard_black.svg')
let dashboard_white = require('./icons_v2/dashboard_white.svg')
let burger_menu = require('./icons/burger_menu_color.svg')
let patients_black = require('./icons/patients_black.svg')
let patients_white = require('./icons/patients_white.svg')
let medical_team_black = require('./icons/medical_team_black.svg')
let medical_team_white = require('./icons/medical_team_white.svg')
let profile_black = require('./icons/profile_black.svg')
let settings_black = require('./icons/setings_black.svg')
let hospital_black = require('./icons_v2/hospital_black.svg')
let hospital_white = require('./icons_v2/hospital_white.svg')
let sign_out_black = require('./icons_v2/sign_out_black.svg')
let vital_checkups = require('./icons_v2/logo_vital_checkups.svg')


let see_more_black = require('./icons_v2/see_more_black.svg')
let see_more_white = require('./icons_v2/see_more_white.svg')

let burger_menu_primary = require('./icons_v2/burger_menu_color.svg')
let profile_settings_black = require('./icons_v2/profile_settings_black.svg')

let abnormalNotification = require('./icons/abnormal_notification_White.svg')
let allNotification = require('./icons/all_notifications_white.svg')
let missed_notification_white = require('./icons/missed_notification_white.svg')
let messages_notification_white = require('./icons/messages_notification_white.svg')
let appointments_white = require('./icons/appointments_white.svg')

let pencilImg = require('./icons/edit_white.svg')
let attach_white = require('./icons/attach_white.svg')
let videocall_white = require('./icons/videocall_white.svg')


let glucose_meter_whtie = require('./icons/glucose_meter_whtie.svg')
let cholesterol_meter_White = require('./icons/cholesterol_meter_White.svg')
let oxymeter_White = require('./icons/oxymeter_White.svg')
let scale_white = require('./icons/scale_white.svg')
let thermometer_White = require('./icons/thermometer_White.svg')
let blood_presure_white = require('./icons/blood_presure_white.svg')
let send_button_white = require('./icons/send_button_white.svg')
let devices_black = require('./icons/devices_black.svg')
let monitoring_plan_black = require('./icons/monitoring_plan_black.svg')
let doctor_black = require('./icons/doctor_black.svg')
let doctor_primary = require('./icons/doctor_primary.svg')
let doctor_secondary = require('./icons/doctor_secondary.svg')
let nurse_black = require('./icons/nurse_black.svg')
let nurse_secondary = require('./icons/nurse_secondary.svg')
let patient_black = require('./icons/patient_black.svg')
let patient_secondary = require('./icons/patient_secondary.svg')

let glucose_meter_primary = require('./icons/glucose_meter_primary.svg')
let cholesterol_meter_primary = require('./icons/cholesterol_meter_primary.svg')
let oxymeter_primary = require('./icons/oxymeter_primary.svg')
let scale_primary = require('./icons/scale_primary.svg')
let thermometer_primary = require('./icons/thermometer_primary.svg')
let blood_pressure_primary = require('./icons/blood_pressure_primary.svg')

let blood_presure_black = require('./icons/blood_presure_black.svg')
let cholesterol_meter_black = require('./icons/cholesterol_meter_black.svg')
let glucose_meter_black = require('./icons/glucose_meter_black.svg')
let oxymeter_black = require('./icons/oxymeter_black.svg')
let scale_black = require('./icons/scale_black.svg')
let thermometer_black = require('./icons/thermometer_black.svg')


let thermometer_grey = require('./icons/thermometer_grey.svg')
let blood_pressure_grey = require('./icons/blood_pressure_grey.svg')
let glucose_meter_grey = require('./icons/glucose_meter_grey.svg')
let oxymeter_grey = require('./icons/oxymeter_grey.svg')
let scale_grey = require('./icons/scale_grey.svg')
let cholesterol_meter_grey = require('./icons/cholesterol_meter_grey.svg')
///devices

export { see_more_white, see_more_black, vital_checkups, burger_menu_primary, sign_out_black, hospital_black, hospital_white, profile_settings_black, medical_team_white, patients_white, dashboard_white, patient_secondary, patient_black, nurse_secondary, nurse_black, doctor_secondary, doctor_primary, doctor_black, thermometer_grey, blood_pressure_grey, glucose_meter_grey, oxymeter_grey, scale_grey, cholesterol_meter_grey, blood_presure_black, cholesterol_meter_black, glucose_meter_black, oxymeter_black, scale_black, thermometer_black, thermometer_primary, scale_primary, glucose_meter_primary, cholesterol_meter_primary, blood_pressure_primary, oxymeter_primary, monitoring_plan_black, devices_black, send_button_white, glucose_meter_whtie, cholesterol_meter_White, oxymeter_White, scale_white, thermometer_White, blood_presure_white, dashboard_black, burger_menu, medical_team_black, patients_black, settings_black, profile_black, abnormalNotification, allNotification, missed_notification_white, messages_notification_white, appointments_white, pencilImg, attach_white, videocall_white }
