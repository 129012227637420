<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
	flat
    color="transparent"
    height="130"
  >
	<v-btn
		class="ml-1 app_bar_button"
		small
		@click="setDrawer(!drawer)"
	>
		<!-- <v-icon color="primary" v-if="drawer">
			mdi-format-list-bulleted-square
		</v-icon> -->

		<!-- <v-icon v-else>
			mdi-view-dashboard
		</v-icon> -->
		<img :src="burger_menu_primary" class="app-bar-settings-icon">
	</v-btn>
	<v-col cols="10" xl="10" lg="8" md="6" sm="6" class="ma-0 pa-0 app_bar_description">
		<span class="app_bar_before_route">Main</span>/<span class="app_bar_path"> {{ pathname }} </span>
		<br/>
		<span class="app_bar_main_path"> {{ pathname }} </span>
	</v-col>
	<v-spacer />
	<v-menu
		rounded="lg"
		bottom
		left
		offset-y
		origin="top right"
		transition="scale-transition"
	>
      <template v-slot:activator="{ attrs, on }">
		<!-- <v-btn v-bind="attrs" v-on="on" class="app_bar_settings_button mr-5">
			<v-icon class="icons">
				mdi-account
			</v-icon>
		</v-btn> -->
		<v-btn v-bind="attrs" v-on="on" class="mr-2 app_bar_settings_button" style="border-radius: 12px">
			<!-- <v-icon class="icons">
				mdi-account
			</v-icon> -->
			<img :src="profile_settings_black" class="app-bar-settings-icon">
			<span class="pl-2 preferences-title">Preferences</span>
		</v-btn>
      </template>

      <v-list style="border-radius: 0px !important" :tile="false" nav dense>
		<template>
			<v-list-item-group>
				<v-list-item style="background-color: #E8EE8E8 !important;" v-for="(item, i) in userMenu" :key="i" @click="redirect(item)">
					<v-list-item-icon>
						<img :src="item.icon" class="px-0">
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title class="d-flex align-left pl-0 pt-1" v-text="item.text"></v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list-item-group>
		</template>
      </v-list>
    </v-menu>
	<ProfileDialog v-if="settingsDialog" :dialog="settingsDialog" @false="settingsDialog = false"></ProfileDialog>
  </v-app-bar>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { burger_menu, settings_black, profile_black } from '@/assets/index'
import ProfileDialog from '../dialogs/ProfileDialog.vue'
import { burger_menu_primary, vital_checkups, profile_settings_black, sign_out_black } from '@/assets/index'

export default {
	name: 'DashboardCoreAppBar',
	components: {
		ProfileDialog
	},
	data: () => ({
		burger_menu_icon: burger_menu,
		profile_icon: profile_black,
		settings_icon: settings_black,
		chosenUnit: 'metric',
		chosenGCh: 'mmol',
		settingsDialog: false,
		profile_settings_black: profile_settings_black,
		burger_menu_primary: burger_menu_primary,
	}),
	computed: {
		...mapGetters({roleName: 'authentication/getRole',}),
        ...mapState(['drawer']),
		pathname() {
            return this.$route.name
        },
		userMenu () {
			return [
				{
					text: 'About',
					action: 'about',
					icon: vital_checkups,
				},
				{
					text: 'Settings',
					action: 'settings',
					icon: profile_settings_black,
				},
				{
					text: 'Sign Out',
					action: 'signout',
					icon: sign_out_black,
				},
			];
		}
	},
	methods: {
        ...mapMutations({
            setDrawer: 'SET_DRAWER',
        }),
		selected (item) {
			localStorage.setItem('unit', item)
			switch(item) {
				case 'metric':
					this.chosenUnit = 'metric'
					break;
				case 'imperial':
					this.chosenUnit = 'imperial'
					break;
			}
		},
		selectedGCh (item) {
			localStorage.setItem('glucose/cholesterol', item)
			switch(item) {
				case 'mmol':
					this.chosenGCh = 'mmol'
					break;
				case 'mg':
					this.chosenGCh = 'mg'
					break;
			}
		},
		signout() {
			this.$router.push({ name: "Login" });
		},
		redirect(item)
		{
			switch(item.action){
				case 'about':
					window.open('https://www.bridge-technology.net/#', '_blank')
					break
				case 'settings':
					this.settingsDialog = true
					break
				case 'signout':
					this.signout()
					break
				default:
					break
			}
		}
    },
};
</script>

<style>
</style>
